import React from 'react'
import Layout from '../components/Layout'
import About from '../components/About'

const NotFoundPage = () => (
  <Layout>
    <main className="cIndex" style={ { marginTop: '10vh' } }>
      <About title="Not Found." text="Whoops. The content you're looking for might be somewhere, but that somewhere sure isn't here." />
    </main>
  </Layout>
)

export default NotFoundPage
